import React, { useState, useMemo } from "react";
import cn from "classnames";
import { Flex, Table } from "@radix-ui/themes";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  ReportDetails,
  ReportType,
  useListReportsV1ReportsGetQuery,
} from "../../app/api/reports/reportsService";
import Spinner from "../../components/Spinner/Spinner";
import { Pagination } from "../../components/Pagination/Pagination";
import { paginationStatusZeroBased } from "../../app/pagination";
import { SortArrows } from "../../components/Table/TableSort";
import { Error } from "../../components/Error";
import { ReportStatus } from "../../components/ReportStatus";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  startDate: string;
  endDate: string;
  reportType: string;
};

const columnHelper = createColumnHelper<ReportDetails>();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("type", {
    header: "Report type",
    cell: (info) => info.getValue(),
    enableSorting: false,
  }),
  columnHelper.accessor("start_date", {
    header: "Start date",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("end_date", {
    header: "End date",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => {
      const value = info.getValue();

      return <ReportStatus value={value as any} />;
    },
  }),
  columnHelper.display({
    id: "download",
    header: "Download",
    cell: (props) => {
      return (
        <a
          href={`/api/proxy/reports/v1/reports/file/${props.row.getValue(
            "id"
          )}`}
          rel="noreferrer"
          target="_blank"
        >
          Download
        </a>
      );
    },
  }),
  columnHelper.display({
    id: "view",
    header: "View",
    cell: (props) => {
      return <Link to={`/report/${props.row.getValue("id")}`}>View</Link>;
    },
  }),
];

export const ReportsTable = (props: Props) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState([{ id: "id", desc: true }]);

  const { isLoading, isError, isFetching, data, error } =
    useListReportsV1ReportsGetQuery({
      type: props.reportType as ReportType,
      startDate: props.startDate,
      endDate: props.endDate,
    });

  const rows = useMemo(() => {
    // memo as otherwise the default empty array will always change reference on render,
    // causing issues for tanstack table
    return data?.reports || [];
  }, [data]);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
      sorting,
    },
  });

  const { getHeaderGroups, getRowModel } = table;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    const errorText = (error as any).data.detail || (error as any).data;

    return <Error>{errorText}</Error>;
  }

  const ps = paginationStatusZeroBased(
    { pageNumber: pagination.pageIndex, pageSize: pagination.pageSize },
    rows.length
  );

  const handleSort = (columnId: string, direction: "asc" | "desc") => {
    table.setSorting([{ id: columnId, desc: direction === "desc" }]);
  };

  return (
    <Flex
      gap="3"
      direction="column"
      className={cn({ isFetching })}
      data-testid="reports-table"
    >
      <Flex justify="between" align="center">
        <div>{props.title}</div>
        <Pagination paginate={table.setPageIndex} status={ps} />
      </Flex>
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            {getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.ColumnHeaderCell
                  key={header.id}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Flex align="center" gap="1">
                    <div
                      onClick={() => header.column.toggleSorting()}
                      style={{ cursor: "pointer", userSelect: "none" }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>

                    {header.column.getCanSort() && (
                      <SortArrows
                        onUpClick={() => handleSort(header.id, "asc")}
                        onDownClick={() => handleSort(header.id, "desc")}
                        upActive={header.column.getIsSorted() === "asc"}
                        downActive={header.column.getIsSorted() === "desc"}
                      />
                    )}
                  </Flex>
                </Table.ColumnHeaderCell>
              ));
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
};
