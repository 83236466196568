import { PageLoading } from "../components/PageLoading/PageLoading";
import React, { useEffect, useRef } from "react";
import { Flex, Table, TextField } from "@radix-ui/themes";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as R from "remeda";

import {
  clustersOverviewPageSelector,
  gotFilter,
  clear,
  showAddModal,
  closeModal,
  addCluster,
} from "../app/clustersOverviewPageReducer";
import * as layout from "../app/layoutReducer";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import styles from "./ClustersOverviewPage.module.scss";
import { YesNo } from "../components/YesNo";
import { router } from "./../App";
import CloudProvider from "../components/CloudProvider";
import {
  ClusterListModel,
  useListClustersV1ClustersGetQuery,
} from "../app/api/ide/ideService";
import { AddEditClusterForm } from "../AddEditClusterForm/AddEditClusterForm";
import { Modal } from "../components/Modal/Modal";
import { notAsked } from "srd";

const queryParms = {
  page: 1,
  pageSize: 100,
};

export function ClustersOverviewPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(clustersOverviewPageSelector);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isSuccess } = useListClustersV1ClustersGetQuery(queryParms);

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([layout.plainBreadcrumb("Clusters")]));
    function handleFocus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    // focus search input on page load
    handleFocus();
  }, []);

  return (
    <div className={styles.page}>
      <Flex justify="between" className={styles.top}>
        <Flex gap="4" direction="column" className={styles.searchInput}>
          <Flex gap="2">
            <div>
              <TextField.Input
                placeholder="Filter..."
                tabIndex={1}
                onInput={(e) => dispatch(gotFilter(e.currentTarget.value))}
                ref={inputRef}
                value={state.filterQuery}
                data-testid="search-input"
              />
            </div>
            <Button color="crimson" onClick={() => dispatch(clear())}>
              Clear
            </Button>
          </Flex>
        </Flex>
        {isSuccess && (
          <Button onClick={() => dispatch(showAddModal())}>Add Cluster</Button>
        )}
      </Flex>
      <ClustersTable filter={state.filterQuery} />
      <AddModal />
    </div>
  );
}

function AddModal() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(clustersOverviewPageSelector);

  const { data } = useListClustersV1ClustersGetQuery(queryParms);

  const clusters: ClusterListModel[] = data?.data || [];

  if (state.modal.modalType === "addCluster") {
    return (
      <Modal open={true} close={() => dispatch(closeModal())}>
        <AddEditClusterForm
          clustersList={clusters}
          submitText="Add cluster"
          heading="Add cluster"
          request={notAsked()}
          onClose={() => dispatch(closeModal())}
          onSubmit={(values) => dispatch(addCluster(values))}
        />
      </Modal>
    );
  }
}

export function ClustersTable(props: { filter: string }) {
  const { data, isError, isLoading, isFetching } =
    useListClustersV1ClustersGetQuery(queryParms);

  if (isLoading) {
    return <PageLoading />;
  }

  if (isError) {
    return <Error>Something went wrong</Error>;
  }

  let clusters: ClusterListModel[] = data?.data || [];

  const filterQuery = props.filter.toLowerCase().trim();

  if (filterQuery !== "") {
    clusters = clusters.filter((row) => {
      return (
        row.region_code.toLowerCase().includes(filterQuery) ||
        row.region.toLowerCase().includes(filterQuery) ||
        row.cloud_provider.toLowerCase().includes(filterQuery) ||
        row.fqdn.toLowerCase().includes(filterQuery)
      );
    });
  }

  // group by region and region codes
  const groupedByRegions = R.pipe(
    clusters,
    R.groupBy((item) => item.region),
    R.values
  ).flat();

  // filter input is first tab index
  const tabIndexBase = 1;

  const goToCluster = (clusterId) => {
    router.navigate(`/cluster/${clusterId}`);
  };

  const fetchingStyles = isFetching ? { opacity: "0.8" } : {};

  return (
    <div style={fetchingStyles}>
      <Table.Root variant="surface" data-testid="results-table">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Region</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Region Code</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Cloud Provider</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Fqdn</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              Cloudflare DNS enabled
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Count</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {groupedByRegions.map((cluster, idx) => (
            <Table.Row
              key={cluster.id}
              onClick={() => goToCluster(cluster.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  goToCluster(cluster.id);
                }
              }}
              className={styles.row}
              tabIndex={tabIndexBase + idx}
            >
              <Table.Cell>{cluster.region}</Table.Cell>
              <Table.Cell>{cluster.region_code}</Table.Cell>
              <Table.Cell>
                <CloudProvider value={cluster.cloud_provider as any} />
              </Table.Cell>
              <Table.Cell>{cluster.fqdn}</Table.Cell>
              <Table.Cell>
                <YesNo value={cluster.enable_cloudflare_dns || false} />
              </Table.Cell>
              <Table.Cell>
                <span
                  className={
                    cluster.instance_count > 200
                      ? cluster.instance_count > 250
                        ? styles.high
                        : styles.medium
                      : styles.low
                  }
                >
                  {cluster.instance_count}
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
}
