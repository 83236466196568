import React, { useEffect, useState } from "react";
import { Heading, Flex, TextField } from "@radix-ui/themes";

import { RecentChats } from "../RecentChats/RecentChats";
import Button from "../../components/Button/Button";
import * as layout from "../../app/layoutReducer";
import { useAppDispatch } from "../../app/hooks";

import styles from "./RecentChatsPage.module.scss";

export const RecentChatsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([]));
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const [activeSearchHostname, setActiveSearchHostname] = useState("");

  const search = () => {
    setActiveSearchHostname(searchInput);
  };

  return (
    <div className={styles.pageWrap}>
      <Flex direction="column" gap="5">
        <div className={styles.header}>
          <Heading>Recent chats</Heading>
          <div>
            <Flex gap="2">
              <div>
                <TextField.Input
                  placeholder="Search by hostname"
                  onInput={(e) => setSearchInput(e.currentTarget.value)}
                  value={searchInput}
                  data-testid="search-input"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </div>
              <Button onClick={search}>Search</Button>
              <Button
                color="crimson"
                onClick={() => {
                  setSearchInput("");
                  setActiveSearchHostname("");
                }}
              >
                Clear
              </Button>
            </Flex>
          </div>
        </div>
        <div>
          <RecentChats hostname={activeSearchHostname} />
        </div>
      </Flex>
    </div>
  );
};
