import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Flex, Heading } from "@radix-ui/themes";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { SRD, RD } from "srd";
import cn from "classnames";
import { useElementSize } from "usehooks-ts";
import ReactJson from "react-json-view";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  TCpuTimeOption,
  fetchActivity,
  fetchInstance,
  gotCpuTimeChange,
  past1Hour,
  past24Hour,
  past8Hour,
  setInstanceName,
  stateSelector,
  fetchIsActive,
} from "../app/instancePageReducer";
import CloudProvider from "../components/CloudProvider";
import { Copyable } from "../components/Copyable/Copyable";
import { Error } from "../components/Error";
import { PageLoading } from "../components/PageLoading/PageLoading";
import ProvisionStatus from "../components/ProvisionStatus";
import { Timestamp } from "../components/Timestamp/Timestamp";
import { YesNo } from "../components/YesNo";
import * as layout from "../app/layoutReducer";

import styles from "./InstancePage.module.scss";
import { CpuChart } from "./CpuChart/CpuChart";
import Spinner from "../components/Spinner/Spinner";
import { TActivityApiResponse } from "../../../server/src/health/types";
import { DiskUsageChart } from "./DiskUsageChart/DiskUsageChart";
import { TInstanceRead } from "../../../server/src/api/ide/ideApi";
import { BundleLink } from "../components/BundleLink";
import { TIsActiveResponse } from "../../../server/src/api/activity/activityApi";
import { PipelineTable } from "../components/PipelinesTable/PipelinesTable";
import { addRecentlyVisitedPad } from "../RecentlyVisited/recentlyVisited";
import { XRDPFix } from "./XRDPFix/XRDPFix";

const Item = (
  props: React.PropsWithChildren<{
    label: string;
    copyText?: string | null;
  }>
) => {
  let copyIcon = <></>;

  const copyText = props.copyText || "";

  if (copyText) {
    copyIcon = <Copyable copyText={copyText} />;
  }

  return (
    <div className={styles.item}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.itemValue}>
        {props.children} {copyIcon}
      </div>
    </div>
  );
};

export function InstancePage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(stateSelector);

  const params = useParams();

  const instanceName = params.instanceName || "";

  const [cpuRef, cpuSize] = useElementSize();

  useEffect(() => {
    dispatch(setInstanceName(instanceName));
    dispatch(fetchInstance());
    dispatch(fetchActivity());
    dispatch(fetchIsActive());
    dispatch(
      layout.setBreadcrumbs([
        layout.plainBreadcrumb("Instance "),
        layout.plainBreadcrumb(instanceName),
      ])
    );

    addRecentlyVisitedPad(instanceName);
  }, []);

  useDocumentTitle(instanceName);

  const generalInfo = (data: TInstanceRead) => (
    <Card data-testid="generalInfo">
      <div className={styles.items}>
        <div className={styles.titleRow}>
          <Heading size="4">General Info</Heading>
        </div>
        <Item label="Name" copyText={data.instance_name}>
          {data.instance_name}
        </Item>
        <Item label="Project" copyText={data.project_id}>
          {data.project_id}
        </Item>
        <Item label="Platform">{data.platform}</Item>
        <Item label="Created at">
          {data.created_at && <Timestamp value={data.created_at} />}
        </Item>

        <Item label="API user">{data.api_user?.name}</Item>
        <Item label="Status">
          {data.provision_status && (
            <ProvisionStatus status={data.provision_status as any} />
          )}
        </Item>
        <Item label="Deallocated">
          <YesNo value={data.is_deallocated || false} />
        </Item>
        <Item label="Health">
          <Link to="health">Check health</Link>
        </Item>
      </div>
    </Card>
  );

  const clusterInfo = (data: TInstanceRead) => (
    <Card>
      <div className={styles.items}>
        <div className={styles.titleRow}>
          <Heading size="4">Cluster Info</Heading>
        </div>
        <Item label="Region">{data.region}</Item>
        <Item label="Cluster">{data.cluster?.name}</Item>
        <Item label="Cluster region code">{data.cluster?.region_code}</Item>
        <Item label="Cloud provider">
          {data.cloud_provider && (
            <CloudProvider value={data.cluster?.cloud_provider as any} />
          )}
        </Item>
      </div>
    </Card>
  );

  const linksInfo = (data: TInstanceRead) => (
    <Flex gap="3" className={styles.links}>
      <a
        target="_blank"
        href={`https://gitlab.builder.ai/projects/${data.project_id}`}
        rel="noreferrer"
      >
        Gitlab project
      </a>

      <div>-</div>

      <a target="_blank" href={data.ide_url} rel="noreferrer">
        Open IDE
      </a>
      <a target="_blank" href={data.ide_direct_url} rel="noreferrer">
        Direct link
      </a>
    </Flex>
  );

  const expertInfo = (data: TInstanceRead) => (
    <Card>
      <div className={cn(styles.items, styles.expertInfo)}>
        <div className={styles.titleRow}>
          <Heading size="4">Expert Info</Heading>
        </div>
        <Item label="Capacity partner" copyText={data.capacity_partner?.email}>
          {data.capacity_partner?.email}
        </Item>
        <Item label="Email alias" copyText={data.capacity_partner?.email_alias}>
          {data.capacity_partner?.email_alias}
        </Item>
      </div>
    </Card>
  );

  const bundleInfo = (data: TInstanceRead) => {
    const ios = data.mobile_emulator_app_ios?.file_path;
    const android = data.mobile_emulator_app_android?.file_path;

    if (!ios && !android) {
      return <></>;
    }

    return (
      <Card>
        <div className={cn(styles.items, styles.expertInfo)}>
          <div className={styles.titleRow}>
            <Heading size="4">Bundle Info</Heading>
          </div>
          {ios && (
            <Item label="iOS app location" copyText={ios}>
              <BundleLink linkText="iOS app location" url={ios} />
            </Item>
          )}
          {android && (
            <Item label="Android app location" copyText={android}>
              <BundleLink linkText="Android app location" url={android} />
            </Item>
          )}
        </div>
      </Card>
    );
  };

  const noData = <div className={styles.centered}>No data</div>;

  const cpuTimeOption = (opt: TCpuTimeOption) => {
    const isActive = state.cpuTimeOption.label === opt.label;

    return (
      <span
        className={cn(styles.timeOption, {
          [styles.timeOptionActive]: isActive,
        })}
        onClick={() => {
          if (!isActive) {
            dispatch(gotCpuTimeChange(opt));
          }
        }}
        data-testid={`cpu-time-option-${opt.label}`}
      >
        [{opt.label}]
      </span>
    );
  };

  const cpuChart = (data: RD<string, TActivityApiResponse>) => (
    <div ref={cpuRef} className={styles.cpuWrap}>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => (
            <div className={styles.centered} data-testid="cpu-chart-loading">
              <Spinner />
            </div>
          ),
          failure: (msg) => (
            <div className={styles.centered}>
              <Error>{msg}</Error>
            </div>
          ),
          success: (res) => {
            if (!res.data || res.data.length === 0) {
              return noData;
            }

            const cpuHistory = res.data.map((item) => {
              return {
                timestamp: item.timestamp,
                cpu: Number(item.instance.cpu),
              };
            });

            return (
              <CpuChart
                data={cpuHistory}
                width={cpuSize.width || 0}
                height={cpuSize.height || 0}
              />
            );
          },
        },
        data
      )}
    </div>
  );

  const diskChart = (data: RD<string, string>) => (
    <div className={styles.diskWrap}>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => (
            <div className={styles.centered}>
              <Spinner />
            </div>
          ),
          failure: (msg) => (
            <div className={styles.centered}>
              <Error>{msg}</Error>
            </div>
          ),
          success: (data) => {
            if (!data) {
              return noData;
            }

            const used = Number(data);
            const free = 100 - used;

            return (
              <DiskUsageChart
                data={[
                  { name: "Free", value: free },
                  { name: "Used", value: used },
                ]}
              />
            );
          },
        },
        data
      )}
    </div>
  );

  const expertActive = (res: RD<string, TIsActiveResponse>) => (
    <div>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => (
            <div className={styles.centered}>
              <Spinner />
            </div>
          ),
          failure: (msg) => (
            <div className={styles.centered}>
              <Error>{msg}</Error>
            </div>
          ),
          success: (isActiveRes) => {
            const isActive = isActiveRes.data.active;

            const message = isActive ? "Expert online" : "Expert offline";

            const testId = isActive ? "expert-online" : "expert-offline";

            return (
              <div className={styles.status} data-testid={testId}>
                <div
                  className={cn(styles.circle, { [styles.active]: isActive })}
                ></div>
                {message}
              </div>
            );
          },
        },
        res
      )}
    </div>
  );

  const activityJson = (data: RD<string, TActivityApiResponse>) => (
    <div>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => (
            <div className={styles.centered}>
              <Spinner />
            </div>
          ),
          failure: (msg) => (
            <div className={styles.centered}>
              <Error>{msg}</Error>
            </div>
          ),
          success: (res) => {
            if (res.data && res.data[0]) {
              return (
                <Card data-testid="activity-json">
                  <div className={styles.titleRow}>
                    <Heading size="4">Activity</Heading>
                  </div>
                  <ReactJson
                    src={res.data[0]}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    theme="monokai"
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                    }}
                  />
                </Card>
              );
            }

            return <></>;
          },
        },
        data
      )}
    </div>
  );

  const tools = (
    <Card>
      <div className={cn(styles.items, styles.expertInfo)}>
        <div className={styles.titleRow}>
          <Heading size="4">Tools</Heading>
        </div>
        <XRDPFix id={instanceName} />
      </div>
    </Card>
  );

  return (
    <div className={styles.instancePage}>
      {SRD.match(
        {
          notAsked: () => <div></div>,
          loading: () => <PageLoading />,
          failure: (msg) => <Error>{msg}</Error>,
          success: (data) => (
            <>
              <div className={styles.top}>
                <div className={styles.indicators}>
                  <div>{expertActive(state.isActiveResponse)}</div>

                  <div className={styles.recentErrors}>
                    <ExclamationTriangleIcon
                      color="red"
                      width="20px"
                      height="20px"
                      className={styles.errorIcon}
                    />
                    Recent errors
                  </div>
                </div>

                {linksInfo(data)}
              </div>

              <div className={styles.metrics}>
                <div className={styles.metricHeading}>
                  CPU usage
                  {cpuTimeOption(past1Hour)}
                  {cpuTimeOption(past8Hour)}
                  {cpuTimeOption(past24Hour)}
                </div>
                <div className={styles.metricHeading}>Disk usage</div>
                {cpuChart(state.activityApiResponse)}
                {diskChart(state.disk)}
              </div>

              <Flex direction="column" gap="4">
                {generalInfo(data)}
                {clusterInfo(data)}
                {tools}
                {expertInfo(data)}
                {bundleInfo(data)}
                <PipelineTable instanceName={instanceName} />
                {activityJson(state.activityApiResponse)}
              </Flex>
            </>
          ),
        },
        state.instance
      )}
    </div>
  );
}
