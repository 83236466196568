import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/chat" }),
  tagTypes: ["RequestCount", "RateLimit", "ChatEnabled"],
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: ({ hostname, limit }) => {
        const params = new URLSearchParams();

        if (hostname) params.append("hostname", hostname);

        if (limit) params.append("limit", limit);

        return `messages?${params}`;
      },
    }),
    getRateLimits: builder.query({
      query: ({ today, maxed }) => {
        const params = new URLSearchParams();

        if (maxed) params.append("maxed", "true");

        if (today) params.append("today", "true");

        return `rate-limits?${params}`;
      },
      providesTags: ["RequestCount", "RateLimit"],
    }),
    getRateLimit: builder.query({
      query: ({ hostname }) => {
        const params = new URLSearchParams();

        params.append("hostname", hostname);

        return `rate-limit?${params}`;
      },
      providesTags: ["RequestCount", "RateLimit"],
    }),
    getDailyRateLimit: builder.query({
      query: () => {
        return "/daily-rate-limit";
      },
      providesTags: ["RateLimit"],
    }),
    postDailyRateLimit: builder.mutation({
      query: (rate_limit) => ({
        url: "/daily-rate-limit",
        method: "POST",
        body: { rate_limit },
      }),
      invalidatesTags: ["RateLimit"],
    }),
    postResetRequestCount: builder.mutation({
      query: (hostname) => ({
        url: "/reset-request-count",
        method: "POST",
        body: { hostname },
      }),
      invalidatesTags: ["RequestCount"],
    }),
    getChatEnabled: builder.query({
      query: () => {
        return "/chat-enabled";
      },
      providesTags: ["ChatEnabled"],
    }),
    postChatEnabled: builder.mutation({
      query: (enabled) => ({
        url: "/chat-enabled",
        method: "POST",
        body: { enabled },
      }),
      invalidatesTags: ["ChatEnabled"],
    }),
  }),
});
