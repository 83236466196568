import React, { useMemo } from "react";
import { Card } from "@radix-ui/themes";

import { Timestamp } from "../../components/Timestamp/Timestamp";
import { chatApi } from "../chatApi";
import { Error } from "../../components/Error";
import { PageLoading } from "../../components/PageLoading/PageLoading";
import { PairedMsgs, sortMessages } from "../sortMessages";

import styles from "./RecentChats.module.scss";

export const RecentChats = (props: { hostname: string }) => {
  const { useGetMessagesQuery } = chatApi;

  const { isLoading, isFetching, isError, data } = useGetMessagesQuery({
    hostname: props.hostname,
    limit: 100,
  });

  const groupedMessages: PairedMsgs[] = useMemo(() => {
    return sortMessages(data);
  }, [data]);

  if (isLoading || isFetching) {
    return <PageLoading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div className={styles.chatPreviewList}>
      {groupedMessages.map((pair) => (
        <ChatPreview key={pair.requestId} msgs={pair} />
      ))}
    </div>
  );
};

function ChatPreview(props: { msgs: PairedMsgs }) {
  return (
    <a
      className={styles.chatPreview}
      href={`/chat/${props.msgs.fromClient.hostname}`}
    >
      <Card>
        <div className={styles.meta}>
          <div className={styles.hostname}>
            {props.msgs.fromClient.hostname}
          </div>
          <div>
            <Timestamp value={props.msgs.fromClient.timestamp} />
          </div>
        </div>
        <div className={styles.fromClient}>{props.msgs.fromClient.text}</div>
        <div className={styles.msg}>{props.msgs.fromChat?.text}</div>
      </Card>
    </a>
  );
}
